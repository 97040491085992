import { API } from '@stoplight/elements';

import '@stoplight/elements/styles.min.css';
import Header from '../components/header.js';
import '../App.css';
import {
  useParams
} from "react-router-dom";


function ServicePage() {
  const { service } = useParams();
  console.log(service)

  const baseURL = window.location.protocol + "//" + window.location.href.split('/')[2] + "/";

  function urlService(service) {
    return baseURL + "docs/" + service + ".yaml"
  }

  return (
    <div className="App">
      <Header service={service} />

      <API apiDescriptionUrl={urlService(service)} router='hash' />
    </div>
  );
}

export default ServicePage;
