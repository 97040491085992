import React from 'react';
import { render } from "react-dom";
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


const root = document.getElementById("root");
render(<App />, root);

reportWebVitals();