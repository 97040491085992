import '@stoplight/elements/styles.min.css';
import Header from '../components/header.js';
import '../App.css';
import {
  useParams
} from "react-router-dom";


function HomePage() {
  const { service } = useParams();
  console.log(service)

  return (
    <div className="App">
      <Header service={service} />
      <h1 className="d-flex justify-content-center mt-5 display-1">API Docs Emtrade</h1>
    </div>
  );
}

export default HomePage;
