import './../App.css';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";


function Header(props) {
    const history = useHistory();
    const service = props.service;

    const baseURL = window.location.protocol + "//" + window.location.href.split('/')[2] + "/";
    const indexURL = baseURL + 'docs/index.json'

    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState("");

    const handleChange = event => {
        console.log(event.target.value);
        history.push("/" + event.target.value);
    };

    useEffect(() => {

        if (options.length === 0) {
            fetch(indexURL).then(response => response.json()).then(data => {

                setOptions(options => [...options, { value: "", text: "Select service" }])

                for (let i = 0; i < data.length; i++) {

                    setOptions(options => [...options, { value: data[i].value, text: data[i].text }]);
                }


            });
        }


    }, [indexURL, options]);

    if (service !== selected) {
        setSelected(service);
    }

    return (

        <div className="App-header d-flex justify-content-center bg-dark">
            <div className="row mb-3 mt-3 col-6">
                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label text-white text-right">Select Docs</label>
                <div className="col-sm-10">
                    <select className="form-select" aria-label="Default select example" value={selected} onChange={handleChange}>
                        {options.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.text}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}

export default Header;
