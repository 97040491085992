import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import ServicePage from './pages/service.js';
import HomePage from './pages/home.js';


function App() {

  return (
    <div>
      <Router>
        <Switch>
           <Route exact path="/:service" component={ServicePage} />
            <Route exact path="/" component={HomePage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
